<template>
  <div
    @click="toggle"
    @keydown.space="toggle"
    :aria-checked="checked"
    :aria-disabled="disabled"
    :class="['BaseCheckbox', { 'clickable': !disabled }]"
    data-cy="base-checkbox"
    :id="labelFor"
    role="checkbox"
    tabindex="0"
  >
    <span
      :class="['BaseCheckbox__checkbox mr-12', {
        'BaseCheckbox__checked': checked
      }]"
      data-cy="base-checkbox-check"
    >
      <transition name="Transition__opacity-fade">
        <i
          v-show="checked"
          class="fa fa-check fa--none fc-white"
        />
      </transition>
    </span>
    <label
      class="fs-14 uppercase"
      :for="labelFor"
    >
      <slot />
    </label>
  </div>
</template>

<script>
/*
 * This has a copy in /marketing-website/components/base-checkbox.vue
 * If you alter this, please alter it copy
*/
export default {
  name: 'BaseCheckbox',

  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    labelFor: {
      type: String,
      required: false,
      default: null
    },
    startingValue: {
      type: Boolean,
      required: false,
    }
  },

  created () {
    this.checked = this.startingValue
  },

  data () {
    return {
      checked: false,
    }
  },

  watch: {
    startingValue () {
      this.checked = this.startingValue
    },
  },

  methods: {
    toggle (e) {
      if (this.disabled) return
      e.preventDefault();
      this.checked = !this.checked
      this.$emit('input', this.checked)
    }
  },
}
</script>

<style lang="sass">
.BaseCheckbox
  +flex($align: center)
  $block: &
  height: $input-height

  &[aria-disabled]
    #{$block}__checked
      background-color: $icon-light
      border-color: lighten($icon-light, .05)

    &:focus,
    &:hover
      #{$block}__checkbox
        border-color: lighten($icon-light, .05)

  &:focus,
  &:hover
    outline: none
    #{$block}__checkbox
      border-color: $branding


  &__checkbox
    +flex($align: center, $justify: center, $shrink: 0)
    background-image: linear-gradient(to top, #F6F7F9, #FFFFFF)
    border: rem(1px) solid $borders
    border-radius: $border-radius
    box-shadow: $shadow-b, inset 0 rem(2px) 0 0 rgba(255, 255, 255, 0.06)
    height: rem(16px)
    // Needs to match the height of a single line of text to be center vertically
    margin-bottom: rem(4px)
    margin-top: rem(4px)
    width: rem(16px)

    .fa-check
      font-size: rem(6px)

  &__checked
    background-color: $branding
    background-image: none
    border-color: #2643A6
    transition: all 0.15s $ease-in-quad
</style>