var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["BaseCheckbox", { clickable: !_vm.disabled }],
      attrs: {
        "aria-checked": _vm.checked,
        "aria-disabled": _vm.disabled,
        "data-cy": "base-checkbox",
        id: _vm.labelFor,
        role: "checkbox",
        tabindex: "0"
      },
      on: {
        click: _vm.toggle,
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
          ) {
            return null
          }
          return _vm.toggle($event)
        }
      }
    },
    [
      _c(
        "span",
        {
          class: [
            "BaseCheckbox__checkbox mr-12",
            {
              BaseCheckbox__checked: _vm.checked
            }
          ],
          attrs: { "data-cy": "base-checkbox-check" }
        },
        [
          _c("transition", { attrs: { name: "Transition__opacity-fade" } }, [
            _c("i", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.checked,
                  expression: "checked"
                }
              ],
              staticClass: "fa fa-check fa--none fc-white"
            })
          ])
        ],
        1
      ),
      _c(
        "label",
        { staticClass: "fs-14 uppercase", attrs: { for: _vm.labelFor } },
        [_vm._t("default")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }